import { FC, PropsWithChildren } from 'react';
import { useActiveCalls } from './use-active-calls';
import { Link } from '@tanstack/react-router';
import { onlyTime } from '@/utils/date.utils';

const ActiveCallsContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <h3 className="mt-8 scroll-m-20 text-2xl font-semibold tracking-tight">Active calls</h3>
      <div className="mt-4">{children}</div>
    </>
  );
};

export const ActiveCalls: FC = () => {
  const { data, error } = useActiveCalls();

  if (error) {
    return <ActiveCallsContainer>{error}</ActiveCallsContainer>;
  }

  if (!data) {
    return <ActiveCallsContainer>Loading...</ActiveCallsContainer>;
  }

  if (!data.length) {
    return <ActiveCallsContainer>No active calls</ActiveCallsContainer>;
  }

  return (
    <ActiveCallsContainer>
      <div>
        <div className="flex justify-between align-middle mb-2">
          <div className="font-semibold text-lg">Total: {data.length}</div>
        </div>
        <ul className="list-disc">
          {data.map((call) => (
            <li className="ml-5 mb-1 last:mb-0" key={call.chatId}>
              <Link className="no-underline flex justify-between" to={`/chats/${call.chatId}`}>
                <div>
                  {call.from}
                  {call.fromName ? ` (${call.fromName})` : ''}
                </div>
                <div>{onlyTime(call.createdAt)}</div>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </ActiveCallsContainer>
  );
};
