import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { VITE_API_URL } from '@/config';

export const useChatRecording = (id: string) => {
  const [url, setUrl] = useState<string | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const { isLoading, error, data, refetch } = useQuery({
    queryKey: [`recordings/${id}`],
    queryFn: async () => {
      const token = await getAccessTokenSilently();

      return axios
        .get(`${VITE_API_URL}/chats/${id}/recording`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        })
        .then((res) => res.data);
    },
    enabled: false,
  });

  useEffect(() => {
    if (data) {
      setUrl(URL.createObjectURL(data));
    }
  }, [data]);

  const handleDownload = () => {
    void refetch();
  };

  return {
    isLoading,
    error,
    url,
    handleDownload,
  };
};
