export const {
  VITE_API_URL,
  DEV: IS_DEV,
  VITE_SENTRY_DSN,
  VITE_SENTRY_AUTH_TOKEN,
  VITE_AUTH0_DOMAIN,
  VITE_AUTH0_CLIENT_ID,
  VITE_AUTH0_AUDIENCE,
  VITE_LANGSMITH_URL,
} = import.meta.env;
