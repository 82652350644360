import { ParsedLocation, redirect, RouteContext } from '@tanstack/react-router';

export const beforeLoad = ({ context, location }: { context: RouteContext; location: ParsedLocation }) => {
  if (!context.auth.isAuthenticated) {
    // eslint-disable-next-line @typescript-eslint/no-throw-literal
    throw redirect({
      to: '/login',
      search: {
        redirect: location.href,
      },
    });
  }
};
