import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card.tsx';
import { toFormattedDate } from '@/lib/date-utils.ts';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip.tsx';
import { Chat } from '@/feature/chat/chat.types.ts';
import { CallDetails } from '../call-details/call-details';

export const ChatDetails = ({ chat }: { chat: Chat }) => {
  const { selectedDepartmentExtension, selectedDepartment } = chat;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Summary</CardTitle>
        <p className="text-sm text-muted-foreground">{chat.summary}</p>
      </CardHeader>
      <CardContent className="grid gap-8">
        <div>
          <div className="grid grid-cols-2">
            <div>
              <h4 className="font-semibold">Date</h4>
              <p className="text-sm text-muted-foreground">{toFormattedDate(chat.createdAt)}</p>
            </div>
            {chat.fromName && (
              <div>
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <div>
                        <h4 className="font-semibold">Selected Department</h4>
                        <p className="text-sm text-muted-foreground">
                          {`${selectedDepartment} (${selectedDepartmentExtension})`}
                        </p>
                      </div>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>Which department user selected on IVR menu</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            )}
          </div>
        </div>
        <div className="grid grid-cols-2">
          <div>
            <h4 className="font-semibold">Caller</h4>
            <p className="text-sm text-muted-foreground">{chat.from}</p>
          </div>
          {chat.fromName && (
            <div>
              <h4 className="font-semibold">Caller Name</h4>
              <p className="text-sm text-muted-foreground">{chat.fromName}</p>
            </div>
          )}
        </div>
        <div className="overflow-auto">
          <h4 className="font-semibold">Call details</h4>
          <CallDetails id={chat.id} />
        </div>
      </CardContent>
    </Card>
  );
};
