import { useEffect, useState } from 'react';
import { Button } from '@/components/ui/button';
import { useCallRecording } from '@/feature/call-recordings/useCallRecording.ts';

export const CallRecordings = ({ id }: { id: string }) => {
  const [url, setUrl] = useState<string | null>(null);
  const { data, isLoading, error, refetch } = useCallRecording(id);

  useEffect(() => {
    if (data) {
      setUrl(URL.createObjectURL(data));
    }
  }, [data]);

  const handleDownload = () => {
    void refetch();
  };

  if (error) {
    return (
      <div className="text-sm">
        Call record was not found.
        <div>{error.message}</div>
      </div>
    );
  }

  return (
    <div className="mt-1">
      {url ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls src={url} />
      ) : (
        <Button variant="outline" onClick={handleDownload} loading={isLoading}>
          Download recording
        </Button>
      )}
    </div>
  );
};
