import { trpc } from '@/trpc.ts';
import { CallStatisticsItem } from '@/feature/call-statistics/call-statistics-item';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { normalizeStatistics } from '@/feature/call-statistics/call-statistics.utils.ts';
import { normalizeTransferTo } from '@/feature/chat/chat.utils.ts';

export const CallStatistics = () => {
  const { data: statisticsData, isLoading } = trpc.getStatistics.useQuery({});
  const {
    totalCals,
    transferredTo,
    totalTransferredTo,
    answeredByReceptionCount,
    transferredToLiveAgentCount,
    loadUpdatesCount,
  } = normalizeStatistics(statisticsData);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!statisticsData) {
    return <div>No data</div>;
  }

  return (
    <>
      <h3 className="mt-8 scroll-m-20 text-2xl font-semibold tracking-tight">Statistics</h3>

      <div className="grid gap-3 lg:grid-cols-2 mt-4">
        <div>
          <div className="grid gap-3 lg:grid-cols-2">
            <CallStatisticsItem title="Total calls answered" count={totalCals} />

            <CallStatisticsItem
              title="Answered by receptionist"
              count={answeredByReceptionCount}
              description="Answered and finished (conversation end)"
            />

            <CallStatisticsItem title="Tansferred to live agent" count={transferredToLiveAgentCount} />

            <CallStatisticsItem
              title="Load updates"
              count={loadUpdatesCount}
              description="User asked for load updates"
            />
          </div>
        </div>

        <div>
          <Card>
            <CardHeader className="space-y-0 pb-0">
              <CardTitle className="text-sm font-medium">
                Total calls transferred to employees/departments: {totalTransferredTo}
              </CardTitle>
            </CardHeader>
            <CardContent>
              <div className="grid gap-3 lg:grid-cols-3 mt-4 max-h-[310px] overflow-y-auto pb-2">
                {transferredTo?.map(
                  ({ count, transferTo, transferToFirstName, transferToLastName, transferToDepartment }) => {
                    const title = `${transferToFirstName} ${transferToLastName}${transferToDepartment ? `, ${transferToDepartment}` : ''}`;
                    const toNormalizedTransferTo = normalizeTransferTo(transferTo);

                    return (
                      <CallStatisticsItem
                        key={transferTo}
                        title={title}
                        count={count}
                        description={toNormalizedTransferTo}
                      />
                    );
                  },
                )}
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  );
};
