import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { VITE_API_URL } from '@/config.ts';
import { useAuth0 } from '@auth0/auth0-react';

export const useCallRecording = (id: string) => {
  const { getAccessTokenSilently } = useAuth0();

  const { isLoading, error, data, isFetching, refetch } = useQuery({
    queryKey: [`recordings/${id}`],
    queryFn: async () => {
      const token = await getAccessTokenSilently();

      return axios
        .get(`${VITE_API_URL}/recordings/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          responseType: 'blob',
        })
        .then((res) => res.data);
    },
    enabled: false,
  });

  return {
    data,
    error,
    refetch,
    isLoading,
    isFetching,
  };
};
