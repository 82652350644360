// Import the generated route tree
import { routeTree } from './routeTree.gen';
import { RouterProvider, createRouter } from '@tanstack/react-router';
import { useAuth0 } from '@auth0/auth0-react';

const router = createRouter({
  routeTree,
  context: {
    auth: {
      isAuthenticated: false,
    },
  },
});

// Register things for typesafety
declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
  interface RouteContext {
    auth: {
      isAuthenticated: false;
    };
  }
}

export const App = () => {
  const { isAuthenticated } = useAuth0();

  return <RouterProvider router={router} context={{ auth: { isAuthenticated } }} />;
};
