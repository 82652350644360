import { Button } from '@/components/ui/button';
import { useChatRecording } from './useChatRecording';

export const ChatRecording = ({ id }: { id: string }) => {
  const { isLoading, error, url, handleDownload } = useChatRecording(id);

  if (error) {
    return (
      <div className="text-sm">
        Recording was not found.
        <div>{error.message}</div>
      </div>
    );
  }

  return (
    <div className="mt-1">
      {url ? (
        // eslint-disable-next-line jsx-a11y/media-has-caption
        <audio controls src={url} />
      ) : (
        <Button variant="outline" onClick={handleDownload} loading={isLoading}>
          Download recording
        </Button>
      )}
    </div>
  );
};
