import { FC, PropsWithChildren, useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { httpLink, splitLink, unstable_httpSubscriptionLink } from '@trpc/client';
import { trpc } from './trpc.ts';
import { useAuth0 } from '@auth0/auth0-react';
import { VITE_API_URL } from './config.ts';
import { EventSourcePolyfill, EventSourcePolyfillInit } from 'event-source-polyfill';
import { minutes } from './utils/time.utls.ts';

// polyfill EventSource https://trpc.io/docs/client/links/httpSubscriptionLink#authorization-by-polyfilling-eventsource
// @ts-ignore
globalThis.EventSource = EventSourcePolyfill;

export const TrpcProvider: FC<PropsWithChildren> = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();

  const [queryClient] = useState(() => new QueryClient());
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        splitLink({
          // uses the httpSubscriptionLink for subscriptions
          condition: (op) => op.type === 'subscription',
          true: unstable_httpSubscriptionLink({
            url: `${VITE_API_URL}/trpc`,
            eventSourceOptions: async () => {
              const authorization = await getAccessTokenSilently();

              return {
                headers: {
                  authorization: `Bearer ${authorization}`,
                },
                heartbeatTimeout: minutes(5),
              } as EventSourcePolyfillInit;
            },
          }),
          false: httpLink({
            url: `${VITE_API_URL}/trpc`,
            // You can pass any HTTP headers you wish here
            async headers() {
              const authorization = await getAccessTokenSilently();

              return {
                authorization: `Bearer ${authorization}`,
              };
            },
          }),
        }),
      ],
    }),
  );

  return (
    <trpc.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </trpc.Provider>
  );
};
