import { createFileRoute } from '@tanstack/react-router';
import { trpc } from '@/trpc.ts';
import { Sidebar } from '@/components/sidebar';
import { beforeLoad } from '@/utils/routes.utils.ts';
import { ChatMessages } from '@/feature/chat/chat-messages.tsx';
import { ChatDetails } from '@/feature/chat/chat-details.tsx';

export const Route = createFileRoute('/chats/$chatId')({
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  component: Chat,
  beforeLoad,
});

function Chat() {
  const { chatId } = Route.useParams();

  const { data, isLoading, error } = trpc.getChat.useQuery(chatId);

  if (error) {
    return <div>Error {error.message}</div>;
  }

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (!data) {
    return <div>Chat not found</div>;
  }

  return (
    <div className="flex min-h-screen w-full flex-col bg-muted/40">
      <Sidebar />

      <div className="sm:gap-4 sm:py-4 sm:pl-14">
        <div className="grid gap-8 grid-cols-2 p-6">
          <div>
            <ChatDetails chat={data} />
          </div>
          <div>
            <ChatMessages messages={data.messages} />
          </div>
        </div>
      </div>
    </div>
  );
}
