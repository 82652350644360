import { FC } from 'react';
import { Button } from '@/components/ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '@/components/ui/table';
import { toFormattedDate } from '@/lib/date-utils';
import { trpc } from '@/trpc';
import { CallRecordings } from '../call-recordings/call-recordings';
import { formatMsToSeconds } from '@/utils/time.utls';

export const CallDetails: FC<{ id: string }> = ({ id }) => {
  const { data, error, isLoading, refetch } = trpc.getCallDetails.useQuery(id, { enabled: false });
  if (error) {
    return (
      <div className="text-sm">
        <div>An error during loading</div>
        <div>{error.message}</div>
      </div>
    );
  }
  if (!data) {
    return (
      <Button className="mt-1" variant="outline" onClick={() => void refetch()} loading={isLoading}>
        Load call details
      </Button>
    );
  }
  if (!data.details.length) {
    return (
      <div className="text-sm">
        <div>No call details found</div>
      </div>
    );
  }
  return (
    <Table className="text-nowrap">
      <TableHeader>
        <TableRow>
          <TableHead className="text-sm">Name</TableHead>
          <TableHead className="text-sm">Phone</TableHead>
          <TableHead className="text-sm">Result</TableHead>
          <TableHead className="text-sm">Duration</TableHead>
          <TableHead className="text-sm">Recording</TableHead>
          <TableHead className="text-sm">Start Time</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.details.map(({ name, phoneNumber, extensionNumber, startTime, result, recordingId, durationMs }) => {
          const phone = `${phoneNumber || ''}${extensionNumber ? ` (${extensionNumber})` : ''}`.trim();
          const [date, time] = toFormattedDate(startTime).split(',');

          return (
            <TableRow key={`${startTime}-${result}`}>
              <TableCell className="text-sm">{name}</TableCell>
              <TableCell className="text-sm">{phone}</TableCell>
              <TableCell className="text-sm">{result}</TableCell>
              <TableCell className="text-sm">{durationMs ? formatMsToSeconds(durationMs) : ''}</TableCell>
              <TableCell className="text-sm">{recordingId ? <CallRecordings id={recordingId} /> : null}</TableCell>
              <TableCell className="text-sm">
                <div>{date}</div>
                <div>{time}</div>
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
