import { createFileRoute, redirect } from '@tanstack/react-router';
import { z } from 'zod';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from '@/components/ui/card.tsx';

const FALLBACK_ROUTE = '/';

export const Route = createFileRoute('/login')({
  validateSearch: z.object({
    redirect: z.string().optional().catch(''),
  }),
  beforeLoad: ({ context, search }) => {
    if (context.auth.isAuthenticated) {
      // eslint-disable-next-line @typescript-eslint/no-throw-literal
      throw redirect({ to: search.redirect || FALLBACK_ROUTE });
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  component: Login,
});

export function Dashboard() {
  return (
    <div className="w-full lg:grid lg:min-h-[600px] lg:grid-cols-2 xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid gap-2 text-center">
            <h1 className="text-3xl font-bold">Login</h1>
            <p className="text-balance text-muted-foreground">Enter your email below to login to your account</p>
          </div>
          <div className="grid gap-4">
            <Button variant="outline" className="w-full">
              Login with Google
            </Button>
          </div>
        </div>
      </div>
      <div className="hidden bg-muted lg:block" />
    </div>
  );
}

function Login() {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = () => {
    void loginWithRedirect();
  };

  return (
    <div className="w-full lg:grid lg:min-h-[600px] xl:min-h-[800px]">
      <div className="flex items-center justify-center py-12">
        <div className="mx-auto grid w-[350px] gap-6">
          <div className="grid  text-center">
            <Card className="mx-auto max-w-sm">
              <CardHeader>
                <CardTitle className="text-xl">Login</CardTitle>
                <CardDescription>Please login using Google to continue</CardDescription>
              </CardHeader>
              <CardContent>
                <div className="grid gap-4">
                  <Button variant="default" className="w-full" onClick={handleLogin}>
                    Login with Google
                  </Button>
                </div>
              </CardContent>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}
