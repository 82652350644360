import { useRef, useState } from 'react';
import { ChevronDownIcon } from '@radix-ui/react-icons';
import { ColumnDef, VisibilityState, flexRender, getCoreRowModel, useReactTable, Row } from '@tanstack/react-table';
import { useVirtualizer } from '@tanstack/react-virtual';
import { Button } from '@/components/ui/button';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { TableBody, TableCell, TableHead, TableHeader, TableHeadRow, TableRow } from '@/components/ui/table';
import { Waypoint } from 'react-waypoint';

type DataTableProps<T> = {
  data: T[];
  columns: ColumnDef<T>[];
  fetchNextPage?: () => void;
  isFetchingNextPage?: boolean;
  hasNextPage?: boolean;
  totalCount: number;
};

export const DataTable = <T,>({
  data,
  columns,
  fetchNextPage,
  isFetchingNextPage,
  hasNextPage,
  totalCount,
}: DataTableProps<T>) => {
  const totalFetched = data.length;
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onColumnVisibilityChange: setColumnVisibility,
    state: {
      columnVisibility,
    },
  });

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 250, // estimate row height for accurate scrollbar dragging
    getScrollElement: () => tableContainerRef.current,
  });

  return (
    <div className="w-full">
      <div className="flex justify-between align-middle py-4">
        <div className="font-semibold text-lg">
          Total items: {totalCount}
          {totalFetched ? `, Displayed items: ${totalFetched}` : null}
        </div>

        <div className="flex items-center justify-content-between space-x-2">
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="outline">
                Columns <ChevronDownIcon className="ml-2 h-4 w-4" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {table
                .getAllColumns()
                .filter((column) => column.getCanHide())
                .map((column) => {
                  return (
                    <DropdownMenuCheckboxItem
                      key={`${column.id}-column`}
                      className="capitalize"
                      checked={column.getIsVisible()}
                      onCheckedChange={(value) => column.toggleVisibility(value)}
                    >
                      {column.id}
                    </DropdownMenuCheckboxItem>
                  );
                })}
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      </div>

      <div
        ref={tableContainerRef}
        // should be a fixed height
        className="overflow-auto relative h-[950px]"
      >
        {/* Even though we're still using sematic table tags, we must use CSS grid and flexbox for dynamic row heights */}
        <table className="grid">
          <TableHeader className="grid">
            {table.getHeaderGroups().map((headerGroup) => (
              <TableHeadRow key={`${headerGroup.id}-headerGroup`} className="flex border border-bottom-0">
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead
                      key={`${header.id}-tableHead`}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: header.getSize(),
                      }}
                    >
                      {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                    </TableHead>
                  );
                })}
              </TableHeadRow>
            ))}
          </TableHeader>
          <TableBody
            // styles should set via inline styles otherwise it could be an error because of virtualization
            style={{
              display: 'grid',
              height: `${rowVirtualizer.getTotalSize()}px`, // tells scrollbar how big the table is
              position: 'relative', // needed for absolute positioning of rows
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<T>;
              return (
                <TableRow
                  data-index={`${virtualRow.index}-tableRow`} // needed for dynamic row height measurement
                  ref={(node) => rowVirtualizer.measureElement(node)} // measure dynamic row height
                  key={`${row.id}-tableRow`}
                  style={{
                    display: 'flex',
                    position: 'absolute',
                    transform: `translateY(${virtualRow.start}px)`, // this should always be a `style` as it changes on scroll
                    width: '100%',
                  }}
                  className="bg-background"
                >
                  {row.getVisibleCells().map((cell) => {
                    return (
                      <TableCell
                        key={`${cell.id}-tableCell-${virtualRow.index}-tableRow`}
                        style={{
                          display: 'flex',
                          width: cell.column.getSize(),
                        }}
                      >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </table>
        {fetchNextPage && hasNextPage ? <Waypoint onEnter={fetchNextPage} /> : null}

        {isFetchingNextPage ? <div className="font-semibold mt-1">Loading more items...</div> : null}
      </div>
    </div>
  );
};
