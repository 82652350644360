import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';

export const CallStatisticsItem = ({
  title,
  count,
  description,
}: {
  title: string;
  count?: number;
  description?: string | null;
}) => {
  return (
    <Card>
      <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
        <CardTitle className="text-sm font-medium">{title}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="text-2xl font-bold">{count}</div>
        {description ? <p className="text-xs text-muted-foreground">{description}</p> : null}
      </CardContent>
    </Card>
  );
};
