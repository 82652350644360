import React from 'react';
import ReactDOM from 'react-dom/client';
import { Auth0Provider } from '@auth0/auth0-react';
import { VITE_AUTH0_AUDIENCE, VITE_AUTH0_CLIENT_ID, VITE_AUTH0_DOMAIN } from './config.ts';
import { TrpcProvider } from './TrpcProvider';

import { App } from '@/App';
import './styles/global.css';

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <Auth0Provider
      domain={VITE_AUTH0_DOMAIN}
      clientId={VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: VITE_AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
      }}
    >
      <TrpcProvider>
        <App />
      </TrpcProvider>
    </Auth0Provider>
  </React.StrictMode>,
);
