import { useState } from 'react';
import { trpc } from '@/trpc';

type ActiveCallsData = {
  chatId: string;
  createdAt: string;
  from: string;
  externalCallId?: string | undefined;
  fromName?: string | undefined;
}[];

export const useActiveCalls = () => {
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<ActiveCallsData | null>(null);

  trpc.onActiveCallsUpdate.useSubscription(
    {},
    {
      onData: (newData) => {
        setData(newData);
        setError(null);
      },
      onError: (newError) => {
        setError(newError.message);
        setData(null);
      },
    },
  );

  return { error, data };
};
